import Swiper from 'swiper/bundle';

const sliderModule = () => {

    if (document.getElementById('slider-news')) {
        const swiper = new Swiper('#slider-news', {
            speed: 400,
            centeredSlides: false,
            loop: false,
    
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
    
            breakpoints: {
                // when window width is >= 0px
                0: {
                    slidesPerView: 1,
                    spaceBetween: 30
                },
                // when window width is >= 575px
                575: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                // when window width is >= 991px
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            },
    
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
    
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
                hide: false,
            }
        });
    }

    if (document.getElementById('slider-about')) {
        const swiper = new Swiper('#slider-about', {
            speed: 400,
            centeredSlides: false,
            loop: false,
            slidesPerView: 1,
            spaceBetween: 30,
            initialSlide: 2,
    
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
    
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }

    if (document.getElementById('branch-staff')) {
        const swiper = new Swiper('#branch-staff', {
            speed: 400,
            centeredSlides: false,
            loop: false,

            breakpoints: {
                // when window width is >= 0px
                0: {
                    slidesPerView: 1,
                    spaceBetween: 30
                },
                // when window width is >= 575px
                575: {
                    slidesPerView: 2,
                    spaceBetween: 30
                }
            },
    
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }

}

export {sliderModule};